import { render, staticRenderFns } from "./Groups.vue?vue&type=template&id=66f579b2&scoped=true&"
import script from "./Groups.vue?vue&type=script&lang=ts&"
export * from "./Groups.vue?vue&type=script&lang=ts&"
import style0 from "./Groups.vue?vue&type=style&index=0&id=66f579b2&scoped=true&lang=css&"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f579b2",
  null
  
)

export default component.exports